import React, { useState, useEffect } from "react";
import {
  useParams,
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  Divider,
  Tabs,
  Tab,
  Card,
  CardContent,
  CardHeader,
  LinearProgress,
  Button,
  TextField,
  IconButton,
  Grid,
  CardActions,
  Breadcrumbs,
  Typography,
  Collapse,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableContainer,
  Paper,
  Tooltip,
  FormControlLabel,
  Checkbox,
  TableCell,
  Toolbar,
} from "@material-ui/core";
import {
  Search,
  TableChart,
  PictureAsPdf,
  Home,
  Dashboard,
  GetApp,
  Add,
  KeyboardArrowRight,
  Work,
  Person,
  Assessment,
  InsertDriveFile,
  Cancel,
  LibraryBooks,
  NoteAdd,
  Check,
  ViewList,
  ViewModule,
  InfoOutlined,
  FileCopy,
  Report,
  DockOutlined,
  FileCopyOutlined,
  Edit,
  EditLocation,
} from "@material-ui/icons";
import TabPanel from "../../../components/TabPanel";
import { formatDate, formatDateTime } from "../../../utils/utils";
import { API } from "aws-amplify";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../../components/StyledTableComponents";
import {
  JobDetail,
  JobMeasureQuoteLineGroup,
  JobMeasureQuoteLineInfo,
  JobLabourInfo,
  JobTagDetailInfo,
  JobLabourTableData,
  JobTagDetailScheduleGroup,
  JobTagDetailOrderGroup,
  NewJobDetail,
  JobClaimsData,
  JobClaimScheduleGroup,
  ScheduleInfo,
} from "../../types/JobTypes";
import InfiniteScroll from "react-infinite-scroller";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineDot,
  TimelineContent,
  ToggleButtonGroup,
  ToggleButton,
} from "@material-ui/lab";
import {
  ClaimContactForm,
  OpportunityHistoryDetail,
} from "../../types/OpportunityTypes";
import { NewClaimDialog } from "./NewClaimDialog";
import { JobMeasureTable } from "./JobMeasureTable";
import { JobTagDetailTable } from "./JobTagDetailTable";
import { JobClaimTable } from "./JobClaimTable";
import { JobLabourTable } from "./JobLabourTable";
import { JobScheduleClaims } from "../../types/JobTypes";
import { JobClaimByScheduleTable } from "./JobClaimByScheduleTable";
import AddToScheduleDialog from "./AddToScheduleDialog";
import SelectSubScheduleDialog from "./SelectSubScheduleDialog";
import { JobTagDetailByScheduleTable } from "./JobTagDetailByScheduleTable";
import { ReportInfo } from "../../../components/ReportViewer";
import { Storage } from "aws-amplify";
import { ClaimDescriptionDialog } from "./ClaimDescriptionDialog";
import { NewLabourClaimDialog } from "./NewLabourClaimDialog";
import { EditLabourRatesDialog } from "./EditLabourRatesDialog";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      display: "flex",
    },
    margin: {
      margin: theme.spacing(1),
    },
    jobDetailCardRow: {
      display: "flex",
      justifyContent: "space-between",
    },
    progress: {
      backgroundColor: "#ff9800",
    },
    progressBackground: {
      backgroundColor: "#ffe0b2",
    },
    textField: {
      marginRight: 8,
    },
    sheetSummaryCard: {
      marginTop: 16,
      backgroundColor: "#1E88E5",
      padding: 8,
      display: "flex",
    },
    horizontalJustifiedLayout: {
      display: "flex",
      justifyContent: "space-between",
    },
    icon: {
      marginRight: theme.spacing(0.5),
      width: 20,
      height: 20,
    },
    link: {
      display: "flex",
    },
    formInput: {
      width: "100%",
    },
    label: {
      marginBottom: 6,
    },
    field: {
      "&.MuiInputBase-root.Mui-disabled": {
        color: "#000000",
      },
      "&.MuiInputBase-input.Mui-disabled": {
        color: "#000000",
      },
    },
    table: {
      minWidth: 650,
    },
    claimsSummaryCard: {
      padding: 16,
      marginTop: 16,
      marginBottom: 16,
    },
    flexDiv: {
      display: "flex",
      justifyContent: "space-between",
    },
    green: {
      color: "green",
    },
    red: {
      color: "red",
    },
  })
);

interface ParamTypes {
  jobId: string;
}

interface Props {
  sheetFrom: string;
  sheetTo: string;
}

interface JobDetailsProps {
  user?: any | null;
}

interface TransactionInfo {
  id: number;
  invoiceNo: string;
  transactionTypeText: string;
  transactionDate: string;
  amount: number;
}

interface TransactionData {
  transactions: Array<TransactionInfo>;
  transactionCount: number;
}

enum DetailingView {
  OrderView = "ORDER",
  ScheduleView = "SCHEDULE",
}
export default function JobDetails({ user }: JobDetailsProps) {
  const classes = useStyles();
  let { jobId } = useParams();
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [jobDetailInfo, setJobDetailInfo] = useState<JobDetail>();
  const [fromSheet, setFromSheet] = useState<string>("");
  const [toSheet, setToSheet] = useState<string>("");
  const [jobSheetInfo, setJobSheetInfo] = useState<Array<any>>([]);

  const [measureData, setMeasureData] = useState<
    Array<JobMeasureQuoteLineInfo>
  >([]);
  const [measureTableData, setMeasureTableData] = useState<
    Array<JobMeasureQuoteLineGroup>
  >([]);
  const [openNewClaimDialog, setOpenNewClaimDialog] = useState<boolean>(false);

  const [labourTableData, setLabourTableData] = useState<
    Array<JobLabourTableData>
  >([]);
  const [tagDetailData, setTagDetailData] = useState<
    Array<JobTagDetailScheduleGroup>
  >([]);
  const [tagDetailDataByGroup, setTagDetailDataByGroup] = useState<
    Array<JobTagDetailOrderGroup>
  >([]);
  const [claimsTableData, setClaimsTableData] = useState<Array<JobClaimsData>>(
    []
  );
  const [claimsByScheduleTableData, setClaimsByScheduleTableData] = useState<
    Array<JobClaimScheduleGroup>
  >([]);
  const [claimView, setClaimView] = useState<string>("claim");
  const [detailingView, setDetailingView] = useState<string>(
    DetailingView.OrderView
  );
  const [claimEditMode, setClaimEditMode] = useState<boolean>(false);
  const [selectedClaimID, setSelectedClaimID] = useState<number>();

  const [transactions, setTransactions] = useState<Array<TransactionInfo>>([]);
  const [hasMoreTransactions, setMoreTransactions] = useState<boolean>(true);

  const [jobHistory, setJobHistory] = useState<Array<OpportunityHistoryDetail>>(
    []
  );
  const [unmatchedTagData, setUnmatchedTagData] = useState<
    Array<JobTagDetailInfo>
  >([]);
  const [claimReports, setClaimReports] = useState<Array<ReportInfo>>([]);
  const [allSchedules, setAllSchedules] = useState<Array<ScheduleInfo>>([]);

  const [showAllStateChecked, setShowAllStateChecked] = React.useState(false);
  // schedules: Array<ScheduleInfo>
  const [showClaimDescriptionDialog, setShowClaimDescriptionDialog] =
    useState<boolean>(false);
  const [claimContact, setClaimContact] = useState<ClaimContactForm>({
    description: "",
    contactName: "",
    email: "",
    phone: "",
    referenceNo: "",
  });
  const [labourClaimContact, setLabourClaimContact] =
    useState<ClaimContactForm>({
      description: "",
      contactName: "",
      email: "",
      phone: "",
      referenceNo: "",
    });
  const [
    showLabourClaimDescriptionDialog,
    setShowLabourClaimDescriptionDialog,
  ] = useState<boolean>(false);
  const [labourClaimEditMode, setLabourClaimEditMode] =
    useState<boolean>(false);
  const [openNewLabourClaimDialog, setOpenNewLabourClaimDialog] =
    useState<boolean>(false);
  const [selectedLabourClaimID, setSelectedLabourClaimID] = useState<number>();

  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (selectedTab === 0) {
      const getJobDetailInfo = () => {
        // setLoading(true)
        // if (jobId && jobId.includes("EC")) {
        //     refreshData()
        // } else {
        //     API.get("", `/encore/jobs/${jobId}`, {})
        //         .then((result: JobDetail) => {
        //             if (result) {
        //                 setJobDetailInfo(result)
        //             }
        //             setLoading(false)
        //         })
        //         .catch((error: any) => {
        //             console.log("Error: fetching job detail", error)
        //             setLoading(false)
        //         })
        // }
      };
      getJobDetailInfo();
    } else if (selectedTab === 4) {
      setLoading(true);
      API.get("", `/encore/jobs/${jobId}/labour`, {})
        .then((response: Array<JobLabourInfo>) => {
          if (response) {
            formatLabourData(response);
          }
          setLoading(false);
        })
        .catch((error: any) => {
          console.log("Error: fetching labour details", error);
          setLoading(false);
        });
    } else if (selectedTab === 5) {
      setTransactions([]);
      setMoreTransactions(true);
    } else if (selectedTab === 6) {
      setLoading(true);
      API.get(
        "",
        `/encore/opportunities/${jobDetailInfo?.oppSeqNo}/history`,
        {}
      )
        .then((response: Array<OpportunityHistoryDetail>) => {
          if (response) {
            setJobHistory(response);
          }
          setLoading(false);
        })
        .catch((error: any) => {
          console.log("Error: fetching opportunity history data", error);
          setLoading(false);
        });
    }
  }, [jobId, selectedTab]);

  useEffect(() => {
    // console.log(location)
    if (location.search) {
      // const params = new URLSearchParams(location.search)
      const sheetFrom = searchParams.get("sheetFrom");
      const sheetTo = searchParams.get("sheetTo");

      setJobSheetInfo([]);
      if (
        sheetFrom !== null &&
        sheetFrom !== "" &&
        sheetTo !== null &&
        sheetTo !== ""
      ) {
        setFromSheet(sheetFrom);
        setToSheet(sheetTo);
        setLoading(true);
        console.log(sheetFrom);
        console.log(sheetTo);

        fetch(
          `/api/encore/jobs/${jobId}/sheets/from/${sheetFrom}/to/${sheetTo}`
        )
          .then((res) => res.json())
          .then((result) => {
            if (result) {
              setJobSheetInfo(result);
            }
            setLoading(false);
          })
          .catch((error: any) => {
            console.log("Error: fetching tags by sheet range", error);
            setLoading(false);
          });
      }
    }
  }, [location]);

  useEffect(() => {
    setLoading(true);
    if (jobId && jobId.includes("EC")) {
      refreshData();
    } else {
      API.get("", `/encore/jobs/${jobId}`, {})
        .then((result: JobDetail) => {
          if (result) {
            setJobDetailInfo(result);
          }
          setLoading(false);
        })
        .catch((error: any) => {
          console.log("Error: fetching job detail", error);
          setLoading(false);
        });
    }

    //   return () => {
    //     second
    //   }
  }, [showAllStateChecked]);

  const refreshData = () => {
    setLoading(true);
    setUnmatchedTagData([]);
    setTagDetailData([]);
    setMeasureData([]);
    API.get(
      "",
      `/encore/jobs/${jobId}/${showAllStateChecked ? "all" : "unclaimed"}`,
      {}
    )
      .then((result: NewJobDetail) => {
        if (result) {
          if (result.s3Location.length > 0) {
            setLoading(true);
            Storage.get(`job/${jobId?.replace("EC", "")}.json`, {
              download: true,
            })
              .then((resp: any) => {
                resp.Body.text().then((str: string) => {
                  //         console.log(str)
                  const res = JSON.parse(str);
                  if (res.unmatchedTags) {
                    setUnmatchedTagData(res.unmatchedTags);
                  }

                  setJobDetailInfo(res.detail);
                  if (res.tags) {
                    formatTagDetailData(res.tags);
                  }
                  if (res.measureData) {
                    setMeasureData(res.measureData);
                    formatMeasureData(
                      res.measureData,
                      res.claimsData,
                      res.tags
                    );
                  }
                  if (res.claimsData) {
                    setClaimsTableData(res.claimsData);
                  }
                  if (res.claimReports) {
                    setClaimReports(res.claimReports);
                  }
                  console.log("s3 fetched");
                  setLoading(false);
                });
              })
              .catch((error: any) => {
                console.log("Error fetching file from s3", error);
              })
              .finally(() => {});
          } else {
            if (result.unmatchedTags) {
              setUnmatchedTagData(result.unmatchedTags);
            }

            setJobDetailInfo(result.detail);
            if (result.tags) {
              formatTagDetailData(result.tags);
            }
            if (result.measureData) {
              setMeasureData(result.measureData);
              formatMeasureData(
                result.measureData,
                result.claimsData,
                result.tags
              );
            }
            if (result.claimsData) {
              setClaimsTableData(result.claimsData);
            }
            if (result.claimReports) {
              setClaimReports(result.claimReports);
            }
          }
        }
      })
      .catch((error: any) => {
        console.log("Error: fetching job detail", error);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleFromSheet = (event: React.ChangeEvent<{ value: string }>) => {
    setFromSheet(event.target.value as string);
  };

  const handleToSheet = (event: React.ChangeEvent<{ value: string }>) => {
    setToSheet(event.target.value as string);
  };

  function getCount(arr: Array<any>): number {
    return arr ? arr.length : 0;
  }

  const handleSearch = () => {
    if (fromSheet !== "" && toSheet !== "") {
      setSearchParams({ sheetFrom: fromSheet, sheetTo: toSheet });
      // navigate(`/encore/jobs/${jobId}`,{  state:{ search:`?sheetFrom=${fromSheet}&sheetTo=${toSheet}` }})
    }
  };

  function getTotalReadyInSheet(v: any) {
    return v.filter(
      (tag: any) =>
        tag.currentTagStatus === "MADE" || tag.currentTagStatus === "ZONE"
    ).length;
  }

  function getTotalNotReadyInSheet(v: any) {
    return v.filter(
      (tag: any) =>
        tag.currentTagStatus === "CONF" ||
        tag.currentTagStatus === "MAKING" ||
        tag.currentTagStatus === "INCOMPLETE"
    ).length;
  }

  function getTotalDispatchedInSheet(v: any) {
    return v.filter((tag: any) => tag.currentTagStatus === "DISPATCHED").length;
  }

  function getTotalDeliveredInSheet(v: any) {
    return v.filter((tag: any) => tag.currentTagStatus === "DELIVERED").length;
  }

  function getColorByStatusCount(v: any) {
    let color = "#fff";
    switch (v) {
      case "PLANNED":
        color = "#e0e0e0";
        break;

      case "MAKING":
        color = "#FFF8E1";
        break;

      case "CONF":
        color = "#E3F2FD";
        break;

      case "MADE":
        color = "#EDE7F6";
        break;

      case "ZONE":
        color = "#FFFDE7";
        break;

      case "DISPATCHED":
        color = "#E8F5E9";
        break;

      default:
        break;
    }
    return color;
  }

  function getStatusAndLocation(item: any): string {
    let str: string = `${item.currentTagStatus}`;
    if (item.currentTagStatus === "ZONE") {
      str += ` - ${item.zone}`;
    } else if (item.currentTagStatus === "MADE") {
      str += ` - ${item.machineNo}`;
    }
    return str;
  }

  function handleTagDetail(tagId: string) {
    navigate(`/encore/tags/${tagId}`);
  }

  function sheetsValid(): boolean {
    return jobSheetInfo.length === 0 ? false : true;
  }

  const formatMeasureData = (
    data: Array<JobMeasureQuoteLineInfo>,
    claims: Array<JobClaimsData>,
    tags: Array<JobTagDetailInfo>
  ) => {
    // const schedules: Array<ScheduleInfo> =
    setAllSchedules(
      data.map((line) => {
        return {
          scheduleNo: line.schedule.toString(),
          scheduleName: line.area,
        };
      })
    );

    let groups = Array<JobMeasureQuoteLineGroup>();

    data.forEach((line: JobMeasureQuoteLineInfo) => {
      let claimedTotal = 0;
      let adjustmentTotal = 0;
      line.claimedAmount = 0;
      line.claimedKg = 0;

      if (claims) {
        claims.forEach((claim: JobClaimsData) => {
          let scheduleClaim = claim.claims.find(
            (c: JobScheduleClaims) => c.scheduleNo === line.schedule
          );
          if (scheduleClaim) {
            claimedTotal += scheduleClaim.claimAmount;
            adjustmentTotal +=
              scheduleClaim.claimAmount - scheduleClaim.actualAmount;
          }
        });
      }

      if (tags) {
        let dispatchedTags = tags.filter(
          (tag: JobTagDetailInfo) =>
            (tag.isSupplyClaimed || tag.isPlaceClaimed) &&
            tag.scheduleNo === line.schedule &&
            tag.rateApplied === line.complexityType &&
            tag.stockCode === line.product
        );
        if (dispatchedTags.length > 0) {
          dispatchedTags.forEach((tag: JobTagDetailInfo) => {
            line.claimedKg += tag.weight;
            const placeAmount =
              tag.placeRate * (tag.isPlaceClaimed ? tag.weight : 0);
            const supplyAmount =
              tag.supplyRate * (tag.isSupplyClaimed ? tag.weight : 0);
            let total = placeAmount + supplyAmount;
            line.claimedAmount += total;
          });
        }
      }

      let index = groups.findIndex(
        (i: JobMeasureQuoteLineGroup) => i.schedule === line.schedule
      );
      if (index >= 0) {
        groups[index].totalWeight += line.kg;
        groups[index].placeTotal += line.placeAmount;
        groups[index].supplyTotal += line.supplyAmount;
        groups[index].total += line.totalAmount;
        groups[index].lines.push(line);
      } else {
        let scheduleClaims = Array<JobScheduleClaims>();

        if (claims) {
          claims.forEach((claim: JobClaimsData) => {
            let scheduleClaim = claim.claims.find(
              (c: JobScheduleClaims) => c.scheduleNo === line.schedule
            );
            if (scheduleClaim) {
              scheduleClaims.push(scheduleClaim);
            }
          });
        }

        let lineGroup: JobMeasureQuoteLineGroup = {
          schedule: line.schedule,
          area: line.area,
          totalWeight: line.kg,
          placeTotal: line.placeAmount,
          supplyTotal: line.supplyAmount,
          total: line.totalAmount,
          lines: [line],
          claimed: claimedTotal,
          adjustmentTotal: adjustmentTotal,
          claims: scheduleClaims,
        };
        groups.push(lineGroup);
      }
    });
    // console.log(groups)
    setMeasureTableData(groups);
  };

  const formatLabourData = (data: Array<JobLabourInfo>) => {
    let activityGroups = Array<JobLabourTableData>();
    data.forEach((labour: JobLabourInfo) => {
      let index = activityGroups.findIndex(
        (i: JobLabourTableData) =>
          i.activityCode === labour.activityCode &&
          i.activityName === labour.activityName
      );
      if (index >= 0) {
        activityGroups[index].totalHours += labour.quantity;
        activityGroups[index].labour.push(labour);
      } else {
        let activityGroup: JobLabourTableData = {
          activityCode: labour.activityCode,
          activityName: labour.activityName,
          totalHours: labour.quantity,
          numEmployees: 0,
          isClaimed: false,
          labour: [labour],
        };
        activityGroups.push(activityGroup);
      }
    });

    activityGroups.forEach((group: JobLabourTableData) => {
      let employees = group.labour.map((a) => a.employeeCode);
      group.numEmployees = new Set(employees).size;
    });
    setLabourTableData(activityGroups);
  };

  const parseTagDetailDataByGroup = (data: Array<JobTagDetailInfo>) => {
    let orderGroups = Array<JobTagDetailOrderGroup>();
    data.forEach((tag: JobTagDetailInfo) => {
      let orderIndex = orderGroups.findIndex(
        (j: JobTagDetailOrderGroup) => j.orderId === tag.orderNo
      );
      if (orderIndex >= 0) {
        orderGroups[orderIndex].tags.push(tag);
        orderGroups[orderIndex].totalTags++;
        if (tag.weightUnits === "Kgs") {
          orderGroups[orderIndex].totalWeight += tag.weight;
        }
        if (
          tag.currentTagStatus === "CONF" ||
          tag.currentTagStatus === "MAKING" ||
          tag.currentTagStatus === "INCOMPLETE"
        ) {
          orderGroups[orderIndex].totalNotReady++;
        } else if (
          tag.currentTagStatus === "MADE" ||
          tag.currentTagStatus === "ZONE"
        ) {
          orderGroups[orderIndex].totalReady++;
        } else if (tag.currentTagStatus === "DISPATCHED") {
          orderGroups[orderIndex].totalDispatched++;
        } else if (tag.currentTagStatus === "DELIVERED") {
          orderGroups[orderIndex].totalDelivered++;
        }
      } else {
        let totalReady = 0,
          totalNotReady = 0,
          totalDispatched = 0,
          totalDelivered = 0,
          totalWeight = 0;
        if (
          tag.currentTagStatus === "CONF" ||
          tag.currentTagStatus === "MAKING" ||
          tag.currentTagStatus === "INCOMPLETE"
        ) {
          totalNotReady = 1;
        } else if (
          tag.currentTagStatus === "MADE" ||
          tag.currentTagStatus === "ZONE"
        ) {
          totalReady = 1;
        } else if (tag.currentTagStatus === "DISPATCHED") {
          totalDispatched = 1;
        } else if (tag.currentTagStatus === "DELIVERED") {
          totalDelivered = 1;
        }

        let orderGroup: JobTagDetailOrderGroup = {
          jobId: tag.jobNo,
          orderId: tag.orderNo,
          controlCode: tag.controlCode,
          sheetNo: tag.sheetNo,
          scheduleNo: tag.scheduleNo,
          scheduleName: tag.scheduleName,
          totalReady: totalReady,
          totalNotReady: totalNotReady,
          totalDispatched: totalDispatched,
          totalDelivered: totalDelivered,
          totalWeight: tag.weightUnits === "Kgs" ? totalWeight : 0,
          totalTags: 1,
          tags: [tag],
          isSupplyClaimed: tag.isSupplyClaimed,
          isPlaceClaimed: tag.isPlaceClaimed,
          selectedTags: 0,
          orderDescription: tag.orderDescription,
          orderPromisedDeliveryDate: tag.orderPromisedDeliveryDate,
          isLabourClaimed: false,
        };
        orderGroups.push(orderGroup);
      }
    });

    //Sort by sheetNo
    orderGroups.sort((a, b) => {
      return a.sheetNo - b.sheetNo;
    });
    setTagDetailDataByGroup(orderGroups);
  };

  const formatTagDetailData = (data: Array<JobTagDetailInfo>) => {
    parseTagDetailDataByGroup(data);
    let scheduleGroups = Array<JobTagDetailScheduleGroup>();
    data.forEach((tag: JobTagDetailInfo) => {
      let index = scheduleGroups.findIndex(
        (i: JobTagDetailScheduleGroup) => i.schedule === tag.scheduleName
      );
      if (index >= 0) {
        let orderIndex = scheduleGroups[index].orders.findIndex(
          (j: JobTagDetailOrderGroup) => j.orderId === tag.orderNo
        );
        if (orderIndex >= 0) {
          scheduleGroups[index].orders[orderIndex].tags.push(tag);
          if (tag.weightUnits === "Kgs") {
            scheduleGroups[index].orders[orderIndex].totalWeight += tag.weight;
            scheduleGroups[index].totalWeight += tag.weight;
          }

          scheduleGroups[index].orders[orderIndex].totalTags++;
          scheduleGroups[index].totalTags++;

          if (
            tag.currentTagStatus === "CONF" ||
            tag.currentTagStatus === "MAKING" ||
            tag.currentTagStatus === "INCOMPLETE"
          ) {
            scheduleGroups[index].totalNotReady++;
            scheduleGroups[index].orders[orderIndex].totalNotReady++;
          } else if (
            tag.currentTagStatus === "MADE" ||
            tag.currentTagStatus === "ZONE"
          ) {
            scheduleGroups[index].totalReady++;
            scheduleGroups[index].orders[orderIndex].totalReady++;
          } else if (tag.currentTagStatus === "DISPATCHED") {
            scheduleGroups[index].totalDispatched++;
            scheduleGroups[index].orders[orderIndex].totalDispatched++;
          } else if (tag.currentTagStatus === "DELIVERED") {
            scheduleGroups[index].totalDelivered++;
            scheduleGroups[index].orders[orderIndex].totalDelivered++;
          }
        } else {
          let totalReady = 0,
            totalNotReady = 0,
            totalDispatched = 0,
            totalDelivered = 0,
            totalWeight = 0;
          if (
            tag.currentTagStatus === "CONF" ||
            tag.currentTagStatus === "MAKING" ||
            tag.currentTagStatus === "INCOMPLETE"
          ) {
            totalNotReady = 1;
            scheduleGroups[index].totalNotReady++;
          } else if (
            tag.currentTagStatus === "MADE" ||
            tag.currentTagStatus === "ZONE"
          ) {
            totalReady = 1;
            scheduleGroups[index].totalReady++;
          } else if (tag.currentTagStatus === "DISPATCHED") {
            totalDispatched = 1;
            scheduleGroups[index].totalDispatched++;
          } else if (tag.currentTagStatus === "DELIVERED") {
            totalDelivered = 1;
            scheduleGroups[index].totalDelivered++;
          }

          if (tag.weightUnits === "Kgs") {
            totalWeight = tag.weight;
          }

          scheduleGroups[index].totalTags++;

          let orderGroup: JobTagDetailOrderGroup = {
            jobId: tag.jobNo,
            orderId: tag.orderNo,
            controlCode: tag.controlCode,
            sheetNo: tag.sheetNo,
            scheduleNo: tag.scheduleNo,
            scheduleName: tag.scheduleName,
            totalReady: totalReady,
            totalNotReady: totalNotReady,
            totalDispatched: totalDispatched,
            totalDelivered: totalDelivered,
            totalWeight: totalWeight,
            totalTags: 1,
            tags: [tag],
            isSupplyClaimed: tag.isSupplyClaimed,
            isPlaceClaimed: tag.isPlaceClaimed,
            selectedTags: 0,
            orderDescription: tag.orderDescription,
            orderPromisedDeliveryDate: tag.orderPromisedDeliveryDate,
            isLabourClaimed: false,
          };
          scheduleGroups[index].orders.push(orderGroup);
        }
      } else {
        let totalReady = 0,
          totalNotReady = 0,
          totalDispatched = 0,
          totalDelivered = 0,
          totalWeight = 0;
        if (
          tag.currentTagStatus === "CONF" ||
          tag.currentTagStatus === "MAKING" ||
          tag.currentTagStatus === "IMCOMPLETE"
        ) {
          totalNotReady = 1;
        } else if (
          tag.currentTagStatus === "MADE" ||
          tag.currentTagStatus === "ZONE"
        ) {
          totalReady = 1;
        } else if (tag.currentTagStatus === "DISPATCHED") {
          totalDispatched = 1;
        } else if (tag.currentTagStatus === "DELIVERED") {
          totalDelivered = 1;
        }

        if (tag.weightUnits === "Kgs") {
          totalWeight = tag.weight;
        }

        let scheduleGroup: JobTagDetailScheduleGroup = {
          schedule: tag.scheduleName,
          scheduleNo: tag.scheduleNo,
          totalReady: totalReady,
          totalNotReady: totalNotReady,
          totalDispatched: totalDispatched,
          totalDelivered: totalDelivered,
          totalWeight: totalWeight,
          totalTags: 1,
          isSupplyClaimed: tag.isSupplyClaimed,
          isPlaceClaimed: tag.isPlaceClaimed,
          selectedTags: 0,
          orders: [
            {
              jobId: tag.jobNo,
              orderId: tag.orderNo,
              controlCode: tag.controlCode,
              sheetNo: tag.sheetNo,
              scheduleNo: tag.scheduleNo,
              scheduleName: tag.scheduleName,
              totalReady: totalReady,
              totalNotReady: totalNotReady,
              totalDispatched: totalDispatched,
              totalDelivered: totalDelivered,
              totalWeight: totalWeight,
              totalTags: 1,
              tags: [tag],
              isSupplyClaimed: tag.isSupplyClaimed,
              isPlaceClaimed: tag.isPlaceClaimed,
              selectedTags: 0,
              orderDescription: tag.orderDescription,
              orderPromisedDeliveryDate: tag.orderPromisedDeliveryDate,
              isLabourClaimed: false,
            },
          ],
        };
        scheduleGroups.push(scheduleGroup);
      }
    });

    setTagDetailData(scheduleGroups);
    // endTime = performance.now()
    // console.log(`Call to Set Data took ${endTime - startTime} milliseconds`)
  };

  const formatClaimDataBySchedule = () => {
    let scheduleGroups = Array<JobClaimScheduleGroup>();

    claimsTableData.forEach((claim: JobClaimsData) => {
      claim.claims.forEach((claimSchedule: JobScheduleClaims) => {
        let scheduleIdx = scheduleGroups.findIndex(
          (c: JobClaimScheduleGroup) => c.schedule === claimSchedule.schedule
        );

        if (scheduleIdx >= 0) {
          scheduleGroups[scheduleIdx].actualPlace += claimSchedule.actualPlace;
          scheduleGroups[scheduleIdx].actualSupply +=
            claimSchedule.actualSupply;
          scheduleGroups[scheduleIdx].actualTotal += claimSchedule.actualAmount;

          scheduleGroups[scheduleIdx].claimPlace += claimSchedule.claimPlace;
          scheduleGroups[scheduleIdx].claimSupply += claimSchedule.claimSupply;
          scheduleGroups[scheduleIdx].claimTotal += claimSchedule.claimAmount;

          scheduleGroups[scheduleIdx].claims.push(claimSchedule);
        } else {
          let scheduleClaim: JobClaimScheduleGroup = {
            schedule: claimSchedule.schedule,
            actualPlace: claimSchedule.actualPlace,
            actualSupply: claimSchedule.actualSupply,
            actualTotal: claimSchedule.actualAmount,
            claimPlace: claimSchedule.claimPlace,
            claimSupply: claimSchedule.claimSupply,
            claimTotal: claimSchedule.claimAmount,
            claims: [claimSchedule],
          };

          scheduleGroups.push(scheduleClaim);
        }
      });
    });

    scheduleGroups = scheduleGroups.sort((a, b) =>
      a.schedule > b.schedule ? 1 : -1
    );

    // console.log(scheduleGroups)
    setClaimsByScheduleTableData(scheduleGroups);
  };

  const calculateClaimTotal = (): number => {
    let total = 0;
    if (claimsTableData) {
      claimsTableData.forEach((claim: JobClaimsData) => {
        total += claim.claimAmount;
      });
    }
    return total;
  };

  const calculateActualTotal = (): number => {
    let total = 0;
    if (claimsTableData) {
      claimsTableData.forEach((claim: JobClaimsData) => {
        total += claim.actualAmount;
      });
    }
    return total;
  };

  const calculateAdjustmentTotal = (): number => {
    let claimTotal = calculateClaimTotal();
    let actualTotal = calculateActualTotal();

    return claimTotal - actualTotal;
  };

  const calculateQuoteTotal = (): number => {
    let quoteTotal = 0;

    if (measureTableData) {
      measureTableData.forEach((schedule: JobMeasureQuoteLineGroup) => {
        quoteTotal += schedule.total;
      });
    }
    return quoteTotal;
  };

  const calculateOutstanding = (): number => {
    let quoteTotal = calculateQuoteTotal();
    let claimedTotal = calculateClaimTotal();

    return claimedTotal - quoteTotal;
  };

  const getAdjustmentClass = (val: number) => {
    if (val > 0) {
      return classes.green;
    } else if (val < 0) {
      return classes.red;
    }
  };

  async function loadMoreTransactions(page: number) {
    const response = await fetch(
      `/api/encore/customers/${jobId?.replaceAll(
        "EC",
        ""
      )}/transactions/offset/${(page - 1) * 50}/rows/50`
    );

    if (!response.ok) {
      console.log(
        "ERROR: fetching customer transactions",
        response.status,
        response.statusText
      );
      return;
    }

    const data: TransactionData = await response.json();

    if (data.transactions !== null) {
      let newTransactions = [...transactions, ...data.transactions];
      setTransactions(newTransactions);

      if (newTransactions.length === data.transactionCount) {
        setMoreTransactions(false);
      }
    } else {
      setMoreTransactions(false);
    }
  }

  const getHistoryItemIcon = (status: string): JSX.Element => {
    let icon;
    switch (status) {
      case "OPPORTUNITY CREATED":
        icon = <Work />;
        break;
      case "CUSTOMER ADDED":
        icon = <Person />;
        break;
      case "MEASURE UPLOADED":
        icon = <Assessment />;
        break;
      case "QUOTE GENERATED":
        icon = <LibraryBooks />;
        break;
      case "FILE UPLOADED":
        icon = <InsertDriveFile />;
        break;
      case "FILE DELETED":
        icon = <Cancel />;
        break;
      case "NOTE ADDED":
        icon = <NoteAdd />;
        break;
      case "OPPORTUNITY LOST":
        icon = <Cancel />;
        break;
      case "JOB STARTED":
        icon = <Check />;
        break;
      default:
        icon = <Home />;
        break;
    }
    return icon;
  };

  const getHistoryItemColor = (status: string): string => {
    let color;
    switch (status) {
      case "OPPORTUNITY CREATED":
        color = "#9ccc65";
        break;
      case "CUSTOMER ADDED":
        color = "#3f51b5";
        break;
      case "MEASURE UPLOADED":
        color = "#42a5f5";
        break;
      case "QUOTE GENERATED":
        color = "#9c27b0";
        break;
      case "FILE UPLOADED":
        color = "#009688";
        break;
      case "FILE DELETED":
        color = "#f50057";
        break;
      case "NOTE ADDED":
        color = "#ec407a";
        break;
      case "OPPORTUNITY LOST":
        color = "#f50057";
        break;
      case "JOB STARTED":
        color = "#4caf50";
        break;
      default:
        color = "#ef5350";
        break;
    }
    return color;
  };

  const claimToggleOnChange = (
    event: React.MouseEvent<HTMLElement>,
    nextView: string | null
  ) => {
    if (nextView !== null) {
      if (nextView === "schedule") {
        formatClaimDataBySchedule();
      }
      setClaimView(nextView);
    }
  };

  const detailingToggleOnChange = (
    event: React.MouseEvent<HTMLElement>,
    nextView: string | null
  ) => {
    if (nextView !== null) {
      if (nextView === DetailingView.ScheduleView) {
        formatClaimDataBySchedule();
      }
      setDetailingView(nextView);
    }
  };

  const onShowClaimDescriptionDialogClick = () => {
    setShowClaimDescriptionDialog(true);
  };
  const onCloseClaimDescriptionDialogClick = () => {
    setShowClaimDescriptionDialog(false);
  };
  const onSubmitClaimDescriptionDialogClick = (
    contactDetails: ClaimContactForm
  ) => {
    setShowClaimDescriptionDialog(false);
    setClaimContact(contactDetails);
    setClaimEditMode(false);
    setOpenNewClaimDialog(true);
  };

  const onShowLabourClaimDescriptionDialogClick = () => {
    setShowLabourClaimDescriptionDialog(true);
  };
  const onCloseLabourClaimDescriptionDialogClick = () => {
    setShowLabourClaimDescriptionDialog(false);
  };
  const onSubmitLabourClaimDescriptionDialogClick = (
    contactDetails: ClaimContactForm
  ) => {
    setShowLabourClaimDescriptionDialog(false);
    setLabourClaimContact(contactDetails);
    setLabourClaimEditMode(false);
    setOpenNewLabourClaimDialog(true);
  };

  return (
    <div>
      <Breadcrumbs>
        <Link to="/encore" className={classes.link}>
          <Home className={classes.icon} />
          Home
        </Link>
        <Link to="/encore/jobs" className={classes.link}>
          <Dashboard className={classes.icon} />
          Jobs
        </Link>
        <Typography color="textPrimary">{jobId}</Typography>
      </Breadcrumbs>
      <div className={classes.header}>
        <h2 style={{ flexGrow: 1 }}>Jobs Details</h2>
        {selectedTab === 0 && (
          <div>
            <TextField
              label="From Sheet"
              className={classes.textField}
              value={fromSheet}
              onChange={handleFromSheet}
            />

            <TextField
              label="To Sheet"
              className={classes.textField}
              value={toSheet}
              onChange={handleToSheet}
            />

            <Button
              variant="contained"
              color="secondary"
              style={{ marginRight: 8, marginTop: 12 }}
              onClick={handleSearch}
            >
              <Search />
              Search
            </Button>

            {sheetsValid() && (
              <>
                <a
                  href={`https://olu5a7l90a.execute-api.ap-southeast-2.amazonaws.com/Prod/api/xlsx/job/${jobId}/?from=${fromSheet}&to=${toSheet}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconButton
                    style={{ marginTop: 6, marginRight: 8 }}
                    color="secondary"
                  >
                    <TableChart />
                  </IconButton>
                </a>
                <a
                  href={`https://olu5a7l90a.execute-api.ap-southeast-2.amazonaws.com/Prod/api/pdf/job/${jobId}/?from=${fromSheet}&to=${toSheet}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconButton
                    style={{ marginTop: 6, marginRight: 8 }}
                    color="secondary"
                  >
                    <PictureAsPdf />
                  </IconButton>
                </a>
              </>
            )}
          </div>
        )}

        <div style={{ marginTop: 12 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={showAllStateChecked}
                onChange={(e) => setShowAllStateChecked(e.target.checked)}
                name="checkedShowAll"
                color="primary"
              />
            }
            label="Show All Tags"
          />
          <Button
            variant="contained"
            target="_blank"
            color="secondary"
            href={`#/report/viewer/113?JobID=${jobDetailInfo?.jobNo.replaceAll(
              "EC",
              ""
            )}&sheetfrom=${fromSheet}&sheetto=${toSheet}`}
          >
            Mill Data
            <FileCopyOutlined style={{ marginLeft: 4 }} />
          </Button>

          {jobDetailInfo?.jobNo.includes("EC") && (
            <Button
              variant="contained"
              color="secondary"
              style={{ marginLeft: 8 }}
              href={`#/encore/opportunities/${jobDetailInfo.oppSeqNo}`}
            >
              Opportunity
              <KeyboardArrowRight />
            </Button>
          )}
        </div>
      </div>

      {jobDetailInfo && (
        <>
          {jobDetailInfo.jobNo.includes("EC") ? (
            <Tabs
              value={selectedTab}
              onChange={(_, newValue) => setSelectedTab(newValue)}
              aria-label="simple tabs example"
            >
              <Tab label="General" />
              <Tab label="Detailing" />
              <Tab label="Measure" />
              <Tab label="Claim" />
              <Tab label="Labour" />
              <Tab label="Transactions" />
              <Tab label="History" />
              {/* <Tab label="External" /> */}
            </Tabs>
          ) : (
            <Tabs
              value={selectedTab}
              onChange={(_, newValue) => setSelectedTab(newValue)}
              aria-label="simple tabs example"
            >
              <Tab label="General" />
            </Tabs>
          )}
        </>
      )}

      <Divider />
      {loading && (
        <LinearProgress
          className={classes.progressBackground}
          classes={{ barColorPrimary: classes.progress }}
        />
      )}
      <TabPanel value={selectedTab} index={0}>
        {!loading && jobDetailInfo && (
          <div style={{ marginTop: 16, marginBottom: 16 }}>
            <Card>
              <CardHeader
                title={`${jobDetailInfo.jobNo} - ${jobDetailInfo.jobTitle}`}
              ></CardHeader>
              <CardContent>
                <div className={classes.jobDetailCardRow}>
                  <span>Account: {jobDetailInfo.accountName}</span>
                </div>
                <div className={classes.jobDetailCardRow}>
                  <span>Total Tags: {jobDetailInfo.totalTags}</span>
                  <span>Made: {jobDetailInfo.totalMade}</span>
                  <span>Dispatched: {jobDetailInfo.totalDispatched}</span>
                  <span>Invoiced: {jobDetailInfo.totalInvoiced}</span>
                </div>
                <div className={classes.jobDetailCardRow}>
                  <span>Total Making: {jobDetailInfo.totalMaking}</span>
                  <span>Total In Zone: {jobDetailInfo.totalInZone}</span>
                </div>
                <div className={classes.jobDetailCardRow}>
                  <span>
                    Active Orders: {getCount(jobDetailInfo.activeOrders)}
                  </span>
                  <span>
                    Processed Orders: {getCount(jobDetailInfo.completedOrders)}
                  </span>
                </div>
                {jobDetailInfo.jobNo.includes("EC") && (
                  <>
                    <div className={classes.jobDetailCardRow}>
                      <span>Type: {jobDetailInfo.type}</span>
                      <span>Branch: {jobDetailInfo.branch}</span>
                    </div>
                    <div className={classes.jobDetailCardRow}>
                      <span>Assigned To: {jobDetailInfo.assignedTo}</span>
                      <span>Status: {jobDetailInfo.status}</span>
                    </div>
                    <div className={classes.jobDetailCardRow}>
                      <span>RFQ Date: {formatDate(jobDetailInfo.rfqDate)}</span>
                      <span>Market Segment: {jobDetailInfo.marketSegment}</span>
                    </div>
                    <div className={classes.jobDetailCardRow}>
                      <span>
                        Est Start Date: {formatDate(jobDetailInfo.estStartDate)}
                      </span>
                      <span>Est Tonnes: {jobDetailInfo.estTonnes}</span>
                    </div>
                    <div className={classes.jobDetailCardRow}>
                      <span>Region: {jobDetailInfo.region}</span>
                    </div>
                    <div className={classes.jobDetailCardRow}>
                      <span>
                        Quote Supply Tonnes: {jobDetailInfo.quoteSupplyTonnes}
                      </span>
                      <span>
                        Quote Place Tonnes: {jobDetailInfo.quotePlaceTonnes}
                      </span>
                    </div>
                    <div className={classes.jobDetailCardRow}>
                      <span>
                        Quote Supply Total: {jobDetailInfo.quoteSupplyTotal}
                      </span>
                      <span>
                        Quote Place Total: {jobDetailInfo.quotePlaceTotal}
                      </span>
                      <span>Total: {jobDetailInfo.quoteTotal}</span>
                    </div>
                  </>
                )}
              </CardContent>
            </Card>
            {jobSheetInfo.length !== 0 &&
              jobSheetInfo.map((sheet: any) => (
                <div key={sheet.sheetNo}>
                  <Card className={classes.sheetSummaryCard}>
                    <div
                      className={classes.horizontalJustifiedLayout}
                      style={{ flexGrow: 1, marginRight: 64 }}
                    >
                      <TextField label="Sheet" value={sheet.sheetNo} disabled />
                      <TextField
                        label="Tag Range"
                        value={`${sheet.tagStart} - ${sheet.tagEnd}`}
                        disabled
                      />
                    </div>
                    <div className={classes.horizontalJustifiedLayout}>
                      <TextField
                        label="Not Ready"
                        value={getTotalNotReadyInSheet(sheet.tags)}
                        disabled
                      />
                      <TextField
                        label="Ready"
                        value={getTotalReadyInSheet(sheet.tags)}
                        disabled
                      />
                      <TextField
                        label="Dispatched"
                        value={getTotalDispatchedInSheet(sheet.tags)}
                        disabled
                      />
                      <TextField
                        label="Delivered"
                        value={getTotalDeliveredInSheet(sheet.tags)}
                        disabled
                      />
                    </div>
                  </Card>

                  <Grid container style={{ marginTop: 16 }} spacing={2}>
                    {sheet.tags.length !== 0 &&
                      sheet.tags.map((tag: any) => (
                        <Grid item xs={12} sm={6} xl={4} key={tag.id}>
                          <Card style={{ padding: 8 }}>
                            <CardContent
                              style={{
                                backgroundColor: getColorByStatusCount(
                                  tag.currentTagStatus
                                ),
                                height: 145,
                              }}
                            >
                              <div
                                className={classes.horizontalJustifiedLayout}
                              >
                                <div style={{ fontWeight: "bold" }}>
                                  <span>Sheet #: {tag.sheetNo}</span>
                                </div>
                                <div style={{ fontWeight: "bold" }}>
                                  <span>Tag #: {tag.tagNo}</span>
                                </div>
                              </div>
                              <div>
                                <span>
                                  ET{tag.id} - {tag.description}
                                </span>
                              </div>
                              <div
                                className={classes.horizontalJustifiedLayout}
                              >
                                <div>
                                  <span>Shape: {tag.shape}</span>
                                </div>
                                <div>
                                  <span>Sheet Type: {tag.sheetType}</span>
                                </div>
                              </div>
                              <div
                                className={classes.horizontalJustifiedLayout}
                              >
                                <div style={{ fontWeight: "bold" }}>
                                  <span>
                                    Status: {getStatusAndLocation(tag)}
                                  </span>
                                </div>
                                <div>
                                  <span>Stock Code: {tag.stockCode}</span>
                                </div>
                              </div>
                              <div
                                className={classes.horizontalJustifiedLayout}
                              >
                                <div>
                                  <span>Weight: {Math.round(tag.weight)}</span>
                                </div>
                                <div>
                                  <span>Length: {tag.length}</span>
                                </div>
                              </div>
                              <div
                                className={classes.horizontalJustifiedLayout}
                              >
                                <div>
                                  <span>Quantity: {tag.quantity}</span>
                                </div>
                              </div>
                            </CardContent>
                            <CardActions style={{ backgroundColor: "#fafafa" }}>
                              <div style={{ flexGrow: 1 }}></div>
                              <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => handleTagDetail(tag.id)}
                              >
                                Tag Detail
                              </Button>
                            </CardActions>
                          </Card>
                        </Grid>
                      ))}
                    {sheet.bundles.length !== 0 &&
                      sheet.bundles.map((bundle: any) => (
                        <BundleCard bundle={bundle} key={bundle.bundleNo} />
                      ))}
                  </Grid>
                </div>
              ))}
          </div>
        )}
      </TabPanel>

      <TabPanel value={selectedTab} index={1}>
        {tagDetailData.length > 0 && (
          <div style={{ marginTop: 8 }}>
            <div style={{ display: "flex" }}>
              <div style={{ flexGrow: 1 }}>
                <ToggleButtonGroup
                  orientation="horizontal"
                  size="small"
                  value={detailingView}
                  exclusive
                  onChange={detailingToggleOnChange}
                >
                  <ToggleButton
                    value={DetailingView.OrderView}
                    disabled={loading}
                  >
                    <Tooltip title="Group by Order">
                      <ViewList />
                    </Tooltip>
                  </ToggleButton>
                  <ToggleButton
                    value={DetailingView.ScheduleView}
                    disabled={loading}
                  >
                    <Tooltip title="Group by Schedule">
                      <ViewModule />
                    </Tooltip>
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
            </div>
            {detailingView === DetailingView.OrderView && (
              <>
                <h2>
                  {showAllStateChecked ? "All Orders" : "Unclaimed Orders"}
                </h2>

                <JobTagDetailTable
                  tagDetailData={tagDetailDataByGroup}
                  refreshData={refreshData}
                  schedules={allSchedules}
                />
              </>
            )}

            {detailingView === DetailingView.ScheduleView && (
              <>
                <h2>
                  {showAllStateChecked
                    ? "All Orders by Schedule"
                    : "Unclaimed Orders by Schedule"}
                </h2>

                <JobTagDetailByScheduleTable tagDetailData={tagDetailData} />
              </>
            )}
          </div>
        )}
      </TabPanel>

      <TabPanel value={selectedTab} index={2}>
        <UnmatchedTagDetailOrderTable
          unmatchedTags={unmatchedTagData}
          refreshData={refreshData}
          jobNo={jobDetailInfo?.jobNo}
          measureTableData={measureTableData}
        ></UnmatchedTagDetailOrderTable>

        {measureTableData.length > 0 && (
          <JobMeasureTable
            measureData={measureTableData}
            refreshData={refreshData}
          />
        )}
      </TabPanel>

      <TabPanel value={selectedTab} index={3}>
        <div style={{ marginTop: 8 }}>
          <div style={{ display: "flex" }}>
            <div style={{ flexGrow: 1 }}>
              <ToggleButtonGroup
                orientation="horizontal"
                size="small"
                value={claimView}
                exclusive
                onChange={claimToggleOnChange}
              >
                <ToggleButton value="claim" disabled={loading}>
                  <Tooltip title="Group by Claim">
                    <ViewList />
                  </Tooltip>
                </ToggleButton>
                <ToggleButton value="schedule" disabled={loading}>
                  <Tooltip title="Group by Schedule">
                    <ViewModule />
                  </Tooltip>
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
            <div>
              {/* <Button variant="contained" color="primary" 
                        style={{ marginRight: 8 }}
                            disabled={unmatchedTagData.length > 0} 
                            onClick={onShowLabourClaimDescriptionDialogClick}>
                                New Labour Claim
                                <Add style={{ marginLeft: 4 }} />
                            </Button> */}
              <Button
                variant="contained"
                color="secondary"
                disabled={unmatchedTagData.length > 0}
                onClick={onShowClaimDescriptionDialogClick}
              >
                New Claim
                <Add style={{ marginLeft: 4 }} />
              </Button>
            </div>
          </div>

          <Card className={classes.claimsSummaryCard}>
            <div className={classes.flexDiv}>
              <span>
                No. of claims: <strong>{claimsTableData.length}</strong>
              </span>
              <span>
                Actual Total:{" "}
                <strong>${calculateActualTotal().toFixed(2)}</strong>
              </span>
              <span>
                Claim Total:{" "}
                <strong>${calculateClaimTotal().toFixed(2)}</strong>
              </span>
              <span>
                Adjustment Total:{" "}
                <strong
                  className={getAdjustmentClass(calculateAdjustmentTotal())}
                >
                  ${calculateAdjustmentTotal().toFixed(2)}
                </strong>
              </span>
            </div>
            <div className={classes.flexDiv} style={{ marginTop: 8 }}>
              <span>
                Quote total:{" "}
                <strong>${calculateQuoteTotal().toFixed(2)}</strong>
              </span>
              <span>
                Outstanding:{" "}
                <strong className={getAdjustmentClass(calculateOutstanding())}>
                  ${calculateOutstanding().toFixed(2)}
                </strong>
              </span>
            </div>
          </Card>

          {claimView === "claim" && (
            <>
              <h2>Claims</h2>

              {claimsTableData.length > 0 && (
                <JobClaimTable
                  claimData={claimsTableData}
                  user={user}
                  refreshData={refreshData}
                  setClaimEditMode={setClaimEditMode}
                  setSelectedClaimID={setSelectedClaimID}
                  claimReports={claimReports}
                  jobNo={jobDetailInfo?.jobNo}
                  canEdit={unmatchedTagData.length === 0}
                />
              )}
            </>
          )}

          {claimView === "schedule" && (
            <>
              <h2>Claims by Schedule</h2>

              {claimsByScheduleTableData.length > 0 && (
                <JobClaimByScheduleTable
                  claimData={claimsByScheduleTableData}
                />
              )}
            </>
          )}
        </div>

        <NewClaimDialog
          open={openNewClaimDialog}
          handleDialog={setOpenNewClaimDialog}
          jobId={jobId!}
          quoteData={measureTableData}
          claimData={claimsTableData}
          user={user}
          refreshData={refreshData}
          claimEditMode={claimEditMode}
          selectedClaimID={selectedClaimID}
          setClaimEditMode={setClaimEditMode}
          setSelectedClaimID={setSelectedClaimID}
          claimContact={claimContact}
        />

        <NewLabourClaimDialog
          open={openNewLabourClaimDialog}
          handleDialog={setOpenNewLabourClaimDialog}
          jobId={jobId!}
          quoteData={measureTableData}
          claimData={claimsTableData}
          user={user}
          refreshData={refreshData}
          claimEditMode={labourClaimEditMode}
          selectedClaimID={selectedLabourClaimID}
          setClaimEditMode={setLabourClaimEditMode}
          setSelectedClaimID={setSelectedLabourClaimID}
          claimContact={labourClaimContact}
        />

        <ClaimDescriptionDialog
          open={showClaimDescriptionDialog}
          claimContact={{
            description: "",
            contactName: "",
            email: "",
            phone: "",
            referenceNo: "",
          }}
          onClose={onCloseClaimDescriptionDialogClick}
          onSubmitClose={onSubmitClaimDescriptionDialogClick}
        ></ClaimDescriptionDialog>
        <ClaimDescriptionDialog
          open={showLabourClaimDescriptionDialog}
          claimContact={{
            description: "",
            contactName: "",
            email: "",
            phone: "",
            referenceNo: "",
          }}
          onClose={onCloseLabourClaimDescriptionDialogClick}
          onSubmitClose={onSubmitLabourClaimDescriptionDialogClick}
        ></ClaimDescriptionDialog>
      </TabPanel>

      <TabPanel value={selectedTab} index={4}>
        {labourTableData.length > 0 && (
          <JobLabourTable labourData={labourTableData} />
        )}
      </TabPanel>

      <TabPanel value={selectedTab} index={5}>
        {(hasMoreTransactions || transactions.length !== 0) && (
          <InfiniteScroll
            pageStart={0}
            loadMore={loadMoreTransactions}
            hasMore={hasMoreTransactions}
            loader={
              <LinearProgress
                className={classes.progressBackground}
                classes={{ barColorPrimary: classes.progress }}
                key={0}
              />
            }
          >
            <TableContainer component={Paper} style={{ marginTop: 16 }}>
              <Table
                className={classes.table}
                size="small"
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Invoice No</StyledTableCell>
                    <StyledTableCell>Type</StyledTableCell>
                    <StyledTableCell>Transaction Date</StyledTableCell>
                    <StyledTableCell>Amount</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {transactions.length !== 0 &&
                    transactions.map((transaction: TransactionInfo) => (
                      <StyledTableRow key={transaction.id}>
                        <StyledTableCell component="th" scope="row">
                          <Link
                            to={`/encore/customers/${jobId?.replaceAll(
                              "EC",
                              ""
                            )}/invoices/${transaction.id}`}
                          >
                            {transaction.invoiceNo}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell>
                          {transaction.transactionTypeText}
                        </StyledTableCell>
                        <StyledTableCell>
                          {formatDate(transaction.transactionDate)}
                        </StyledTableCell>
                        <StyledTableCell>
                          {transaction.amount.toFixed(2)}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </InfiniteScroll>
        )}
        {!hasMoreTransactions && transactions.length === 0 && (
          <p>No transactions found</p>
        )}
      </TabPanel>

      <TabPanel value={selectedTab} index={6}>
        <div>
          {jobHistory.length !== 0 && (
            <Card style={{ marginTop: 16 }}>
              <CardHeader title="History Timeline" />
              <CardContent>
                <Timeline align="alternate">
                  {jobHistory.length !== 0 &&
                    jobHistory.map(
                      (item: OpportunityHistoryDetail, index: number) => (
                        <TimelineItem key={item.id}>
                          <TimelineSeparator>
                            <TimelineDot
                              style={{
                                backgroundColor: getHistoryItemColor(
                                  item.historyType
                                ),
                              }}
                            >
                              {getHistoryItemIcon(item.historyType)}
                            </TimelineDot>
                            {index !== navigate.length - 1 && (
                              <TimelineConnector />
                            )}
                          </TimelineSeparator>
                          <TimelineContent>
                            <Paper
                              elevation={3}
                              style={{ padding: "6px 16px" }}
                            >
                              <Typography variant="h6" component="h1">
                                {item.historyType}
                              </Typography>
                              <Typography variant="body1" component="h1">
                                {item.description}
                              </Typography>
                              <Typography variant="caption">
                                {item.recordedBy} -{" "}
                                {formatDateTime(item.recordedAt)}
                              </Typography>
                            </Paper>
                          </TimelineContent>
                        </TimelineItem>
                      )
                    )}
                </Timeline>
              </CardContent>
            </Card>
          )}
        </div>
      </TabPanel>
      <TabPanel value={selectedTab} index={7}>
        <ExternalLabourTable measureData={measureData}></ExternalLabourTable>
      </TabPanel>
    </div>
  );
}

interface ExternalLabourDataProperties {
  measureData: Array<JobMeasureQuoteLineInfo>;
}

function ExternalLabourTable({ measureData }: ExternalLabourDataProperties) {
  const classes = useStyles();

  const [openEditLabourRatesDialog, setOpenEditLabourRatesDialog] =
    useState<boolean>(false);
  const [selected, setSelected] = React.useState<number[]>([]);
  const rowCount = measureData.length;
  const numSelected = selected.length;

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = measureData.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  return (
    <>
      <Toolbar>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1" component="div">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle" component="div">
            External Labour Rates
          </Typography>
        )}
        {numSelected > 0 ? (
          <>
            <Button
              variant="contained"
              style={{ marginLeft: 8, marginRight: 8 }}
              onClick={() => setOpenEditLabourRatesDialog(true)}
            >
              Edit Labour Rate
              <Edit />
            </Button>

            {/* <Tooltip title="Delete">
                    <IconButton aria-label="delete">
                        <Delete />
                    </IconButton>
                    </Tooltip> */}
          </>
        ) : (
          <>
            {/* <Button variant="contained"  style={{marginLeft:8, marginRight:8}}>
                    Detail
                    <Add  />
                </Button> */}
          </>
        )}
      </Toolbar>
      <TableContainer component={Paper} style={{ marginTop: 16 }}>
        <Table className={classes.table} size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell padding="checkbox" style={{ paddingLeft: 8 }}>
                <Checkbox
                  indeterminate={numSelected > 0 && numSelected < rowCount}
                  checked={rowCount > 0 && numSelected === rowCount}
                  onChange={handleSelectAllClick}
                  inputProps={{ "aria-label": "select all items" }}
                />
              </StyledTableCell>
              <StyledTableCell>Schedule</StyledTableCell>
              <StyledTableCell>Type</StyledTableCell>
              <StyledTableCell>Product</StyledTableCell>
              <StyledTableCell>Labour Rate</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {measureData.map(
              (measure: JobMeasureQuoteLineInfo, idx: number) => {
                const isItemSelected = isSelected(measure.id);
                return (
                  <StyledTableRow
                    key={measure.id}
                    hover
                    onClick={(event) => handleClick(event, measure.id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    selected={isItemSelected}
                  >
                    <StyledTableCell padding="checkbox">
                      <Checkbox
                        checked={isItemSelected}
                        // inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </StyledTableCell>
                    <StyledTableCell>{`${measure.schedule} - ${measure.area}`}</StyledTableCell>
                    <StyledTableCell>{measure.type}</StyledTableCell>
                    <StyledTableCell>{measure.product}</StyledTableCell>
                    <StyledTableCell>{measure.labourRate}</StyledTableCell>
                  </StyledTableRow>
                );
              }
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <EditLabourRatesDialog
        openDialog={openEditLabourRatesDialog}
        onCloseDialog={() => setOpenEditLabourRatesDialog(false)}
      ></EditLabourRatesDialog>
    </>
  );
}

interface BundleProps {
  bundle: any;
}

function BundleCard({ bundle }: BundleProps) {
  const classes = useStyles();
  const [expandBundle, setExpandBundle] = useState<boolean>(false);

  const navigate = useNavigate();

  function getColorByStatusCount(v: any) {
    let color = "#fff";
    switch (v) {
      case "PLANNED":
        color = "#e0e0e0";
        break;

      case "MAKING":
        color = "#FFF8E1";
        break;

      case "CONF":
        color = "#E3F2FD";
        break;

      case "MADE":
        color = "#EDE7F6";
        break;

      case "ZONE":
        color = "#FFFDE7";
        break;

      case "DISPATCHED":
        color = "#E8F5E9";
        break;

      default:
        break;
    }
    return color;
  }

  function getStatusAndLocation(item: any): string {
    let str: string = `${item.currentTagStatus}`;
    if (item.currentTagStatus === "ZONE") {
      str += ` - ${item.zone}`;
    } else if (item.currentTagStatus === "MADE") {
      str += ` - ${item.machineNo}`;
    }
    return str;
  }

  function handleTagDetail(tagId: string) {
    navigate(`/encore/tags/${tagId}`);
  }

  function toTons(v: number) {
    return `${(v / 1000).toFixed(2)}T`;
  }

  return (
    <Grid item xs={12} sm={6} xl={4}>
      <Card style={{ padding: 8 }}>
        <CardContent
          style={{
            backgroundColor: getColorByStatusCount(bundle.currentTagStatus),
            height: 145,
          }}
        >
          <div className={classes.horizontalJustifiedLayout}>
            <div style={{ fontWeight: "bold" }}>
              <span>Sheet #: {bundle.sheetNo}</span>
            </div>
            <div style={{ fontWeight: "bold" }}>
              <span>Bundled Tags #: {bundle.totalTags}</span>
            </div>
          </div>
          <div className={classes.horizontalJustifiedLayout}>
            <div style={{ fontWeight: "bold" }}>
              <span>Bundle Weight: {toTons(bundle.bundleWeight)}</span>
            </div>
            <div style={{ fontWeight: "bold" }}>
              <span>Bundle QTY: {bundle.bundleQuantity}</span>
            </div>
          </div>
        </CardContent>
        <CardActions style={{ backgroundColor: "#fafafa" }}>
          <div style={{ flexGrow: 1 }}></div>
          <Button
            variant="contained"
            style={{ backgroundColor: "#f44336", color: "white" }}
            onClick={() => setExpandBundle(!expandBundle)}
          >
            Bundle Detail
          </Button>
        </CardActions>
      </Card>
      <Collapse in={expandBundle}>
        {bundle.tags.length !== 0 &&
          bundle.tags.map((tag: any) => (
            <Card style={{ padding: 8, marginTop: 16 }} key={tag.id}>
              <CardContent
                style={{
                  backgroundColor: getColorByStatusCount(tag.currentTagStatus),
                  height: 145,
                }}
              >
                <div className={classes.horizontalJustifiedLayout}>
                  <div style={{ fontWeight: "bold" }}>
                    <span>Sheet #: {tag.sheetNo}</span>
                  </div>
                  <div style={{ fontWeight: "bold" }}>
                    <span>Tag #: {tag.tagNo}</span>
                  </div>
                </div>
                <div>
                  <span>
                    ET{tag.id} - {tag.description}
                  </span>
                </div>
                <div className={classes.horizontalJustifiedLayout}>
                  <div>
                    <span>Shape: {tag.shape}</span>
                  </div>
                  <div>
                    <span>Sheet Type: {tag.sheetType}</span>
                  </div>
                </div>
                <div className={classes.horizontalJustifiedLayout}>
                  <div style={{ fontWeight: "bold" }}>
                    <span>Status: {getStatusAndLocation(tag)}</span>
                  </div>
                  <div>
                    <span>Stock Code: {tag.stockCode}</span>
                  </div>
                </div>
                <div className={classes.horizontalJustifiedLayout}>
                  <div>
                    <span>Weight: {Math.round(tag.weight)}</span>
                  </div>
                  <div>
                    <span>Length: {tag.length}</span>
                  </div>
                </div>
                <div className={classes.horizontalJustifiedLayout}>
                  <div>
                    <span>Quantity: {tag.quantity}</span>
                  </div>
                </div>
              </CardContent>
              <CardActions style={{ backgroundColor: "#fafafa" }}>
                <div style={{ flexGrow: 1 }}></div>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => handleTagDetail(tag.id)}
                >
                  Tag Detail
                </Button>
              </CardActions>
            </Card>
          ))}
      </Collapse>
    </Grid>
  );
}

export const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

interface UnmatchedTagDetailOrderTableProps {
  unmatchedTags: Array<JobTagDetailInfo>;
  jobNo?: string;
  refreshData: () => void;
  measureTableData: Array<JobMeasureQuoteLineGroup>;
}
function UnmatchedTagDetailOrderTable({
  unmatchedTags,
  refreshData,
  jobNo,
  measureTableData,
}: UnmatchedTagDetailOrderTableProps) {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  const [openSubScheduleDialog, setOpenSubScheduleDialog] =
    useState<boolean>(false);
  const [selectedJobMeasureQuoteInfo, setSelectedJobMeasureQuoteInfo] =
    useState<JobMeasureQuoteLineInfo>();
  const [similarQuotes, setSimilarQuotes] =
    useState<Array<JobMeasureQuoteLineInfo>>();
  const [isNewItem, setIsNewItem] = useState<boolean>(false);

  const handleClickOpen = (index: number, dialogType: string) => {
    setIsNewItem(false);
    const tag = unmatchedTags[index];
    const simQuotes: Array<JobMeasureQuoteLineInfo> = [];
    measureTableData.forEach((sch) => {
      sch.lines.forEach((item) => {
        if (item.product === tag.stockCode) {
          simQuotes.push(item);
        }
      });
    });
    setSimilarQuotes(simQuotes);

    setSelectedJobMeasureQuoteInfo({
      id: tag.id,
      measureId: 0,
      schedule: tag.scheduleNo,
      item: 0, //TODO: workout count in list
      type: toNormalCase(tag.shapeTypeDescr),
      product: tag.stockCode,
      kg: 0, //tag.weight,
      claimedKg: 0,
      supplyRate: 0,
      placeRate: 0,
      supplyAmount: 0,
      placeAmount: 0,
      totalAmount: 0,
      claimedAmount: 0,
      uom: tag.weightUnits === "Kgs" ? "Kg" : tag.weightUnits,
      area: tag.scheduleName,
      complexityType: "",
      reasonCodeId: tag.reasonCodeId,
      jobSeqNo: parseInt(tag.jobNo.toString()),
      labourRate: 0,
    });
    if (dialogType === "selectSubSchedule") {
      setOpenSubScheduleDialog(true);
    } else {
      setOpen(true);
    }
  };

  const handleClicNewScheduleItem = () => {
    setIsNewItem(true);
    setSimilarQuotes([]);
    setSelectedJobMeasureQuoteInfo({
      id: 0,
      measureId: 0,
      schedule: 0,
      item: 0,
      type: "",
      product: "",
      kg: 0, //tag.weight,
      claimedKg: 0,
      supplyRate: 0,
      placeRate: 0,
      supplyAmount: 0,
      placeAmount: 0,
      totalAmount: 0,
      claimedAmount: 0,
      uom: "Kg",
      area: "",
      complexityType: "",
      reasonCodeId: "",
      jobSeqNo: jobNo ? parseInt(jobNo?.replace("EC", "")) : 0,
      labourRate: 0,
    });
    setOpen(true);
  };

  const handleClose = (value: boolean) => {
    setOpen(false);
    setOpenSubScheduleDialog(false);
    refreshData();
    // setSelectedValue(value);
  };

  function toNormalCase(str: string): string {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  return (
    <>
      <div style={{ marginTop: 8, display: "flex" }}>
        <div style={{ flexGrow: 1 }}></div>
        <div>
          <Button
            variant="contained"
            color="secondary"
            style={{ marginLeft: 8 }}
            onClick={handleClicNewScheduleItem}
          >
            New Item
            <Add style={{ marginLeft: 4 }} />
          </Button>
        </div>
      </div>
      {unmatchedTags && unmatchedTags.length > 0 && (
        <div>
          <h1>
            Unmatched Tags <span>({unmatchedTags.length})</span>
          </h1>

          <TableContainer
            component={Paper}
            style={{ marginTop: 16, height: 400 }}
          >
            <Table size="small">
              <TableHead>
                <TableRow>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell>Control Code</StyledTableCell>
                  <StyledTableCell>Sched.</StyledTableCell>
                  <StyledTableCell>Sheet No</StyledTableCell>
                  <StyledTableCell>Tag No</StyledTableCell>
                  <StyledTableCell>Stock Code</StyledTableCell>
                  <StyledTableCell>Length</StyledTableCell>
                  <StyledTableCell>Quantity</StyledTableCell>
                  <StyledTableCell>Weight</StyledTableCell>
                  <StyledTableCell>Status</StyledTableCell>
                  <StyledTableCell>Type</StyledTableCell>
                  <StyledTableCell>Reason Code</StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {unmatchedTags.map((tag: JobTagDetailInfo, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell>
                      <Tooltip title={tag.notes} arrow>
                        <InfoOutlined />
                      </Tooltip>
                    </StyledTableCell>
                    <StyledTableCell>{tag.controlCode}</StyledTableCell>
                    <StyledTableCell>{tag.scheduleNo}</StyledTableCell>
                    <StyledTableCell>{tag.sheetNo}</StyledTableCell>
                    <StyledTableCell>{tag.tagNo}</StyledTableCell>
                    <StyledTableCell>{tag.stockCode}</StyledTableCell>
                    <StyledTableCell>{tag.length}</StyledTableCell>
                    <StyledTableCell>{tag.quantity}</StyledTableCell>
                    <StyledTableCell>{Math.round(tag.weight)}</StyledTableCell>
                    <StyledTableCell>{tag.currentTagStatus}</StyledTableCell>
                    <StyledTableCell>
                      {toNormalCase(tag.shapeTypeDescr)}
                    </StyledTableCell>
                    <StyledTableCell>
                      {tag.isNoCharge
                        ? `${tag.reasonCodeId} - No Charge`
                        : tag.reasonCodeId}
                    </StyledTableCell>
                    <StyledTableCell>
                      {tag.reasonCodeId.length > 0 &&
                      !tag.isNoCharge &&
                      !tag.isAssignedVariationSubSchedule ? (
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() =>
                            handleClickOpen(index, "selectSubSchedule")
                          }
                        >
                          Select Sub-Schedule
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => handleClickOpen(index, "addSchedule")}
                        >
                          Add to Schedule
                        </Button>
                      )}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
      <AddToScheduleDialog
        openDialog={open}
        handleCloseDialog={handleClose}
        jobMeasureQuoteInfo={selectedJobMeasureQuoteInfo}
        isNewItem={isNewItem}
        similarQuotes={similarQuotes}
      ></AddToScheduleDialog>
      <SelectSubScheduleDialog
        openDialog={openSubScheduleDialog}
        handleCloseDialog={handleClose}
        jobMeasureQuoteInfo={selectedJobMeasureQuoteInfo}
      ></SelectSubScheduleDialog>
    </>
  );
}
